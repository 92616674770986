import React from "react";

export default function Abilities() {
  return (
    <div>
      <div className="pt-[2rem] md:pt-[4rem] md:block hidden">
        <div className="flex justify-between items-center relative h-[3rem] mb-[2rem]">
          <div className="bg-orange text-white h-[4rem] flex align-middle items-center duration-300 ease-in-out rounded-tr-full rounded-br-full py-[0.5rem] hover:w-full w-full lg:w-[25rem] z-0">
            <p className="my-auto md:text-[22px] relative z-10 w-[25rem] text-left pl-[1rem] lg:pl-0 lg:text-center">
              We are Agile
            </p>
          </div>
          <p className="my-auto absolute right-0 lg:text-[16px] w-[65%] md:w-[50%] z-10 pr-[1rem] font-[400]">
            We treasure cycles of learning and improving. We adapt to changes
            quickly and smoothly.
          </p>
        </div>
        <div className="flex justify-between items-center relative h-[3rem] mb-[2rem]">
          <div className="bg-orange text-white h-[4rem] flex align-middle items-center duration-300 ease-in-out rounded-tr-full rounded-br-full py-[0.5rem] hover:w-full w-full lg:w-[30rem] z-0">
            <p className="my-auto md:text-[22px] relative z-10 w-[30rem] text-left pl-[1rem] lg:pl-0 lg:text-center">
              We are Awesome
            </p>
          </div>
          <p className="my-auto absolute right-0 lg:text-[16px] w-[65%] md:w-[50%] z-10 pr-[1rem] font-[400]">
            We create useful softwares that meets the requirements. We create
            softwares that meets quality standards.
          </p>
        </div>
        <div className="flex justify-between items-center relative h-[3rem] mb-[2rem]">
          <div className="bg-orange text-white h-[4rem] flex align-middle items-center duration-300 ease-in-out rounded-tr-full rounded-br-full py-[0.5rem] hover:w-full w-full lg:w-[25rem] z-0">
            <p className="my-auto md:text-[22px] relative z-10 w-[25rem] text-left pl-[1rem] lg:pl-0 lg:text-center">
              We are Friendly
            </p>
          </div>
          <p className="my-auto absolute right-0 lg:text-[16px] w-[65%] md:w-[50%] z-10 pr-[1rem] font-[400]">
            We teach and learn from one another in many ways. We communicate and
            brainstorm as a whole team.
          </p>
        </div>
      </div>

      <div className="pt-[2rem] md:pt-[4rem] md:hidden block">
        <div className="flex-row justify-between items-center relative mb-[2rem]">
          <div className="bg-orange text-white h-[4rem] flex align-middle items-center duration-300 ease-in-out rounded-tr-full rounded-br-full py-[0.5rem] hover:w-full w-full lg:w-[25rem] z-0">
            <p className="my-auto md:text-[22px] relative z-10 w-[25rem] text-left pl-[1rem] lg:pl-0 lg:text-center">
              We are Agile
            </p>
          </div>
          <p className="my-auto lg:text-[16px] font-[400]">
            We treasure cycles of learning and improving. We adapt to changes
            quickly and smoothly.
          </p>
        </div>
        <div className="flex-row justify-between items-center relative mb-[2rem]">
          <div className="bg-orange text-white h-[4rem] flex align-middle items-center duration-300 ease-in-out rounded-tr-full rounded-br-full py-[0.5rem] hover:w-full w-full lg:w-[25rem] z-0">
            <p className="my-auto md:text-[22px] relative z-10 w-[25rem] text-left pl-[1rem] lg:pl-0 lg:text-center">
              We are Awesome
            </p>
          </div>
          <p className="my-auto lg:text-[16px] font-[400]">
            We create useful softwares that meets the requirements. We create
            softwares that meets quality standards.
          </p>
        </div>
        <div className="flex-row justify-between items-center relative mb-[2rem]">
          <div className="bg-orange text-white h-[4rem] flex align-middle items-center duration-300 ease-in-out rounded-tr-full rounded-br-full py-[0.5rem] hover:w-full w-full lg:w-[25rem] z-0">
            <p className="my-auto md:text-[22px] relative z-10 w-[25rem] text-left pl-[1rem] lg:pl-0 lg:text-center">
              We are Friendly
            </p>
          </div>
          <p className="my-auto lg:text-[16px] font-[400]">
            We teach and learn from one another in many ways. We communicate and
            brainstorm as a whole team.
          </p>
        </div>
      </div>
    </div>
  );
}
