import React, { useState } from "react";
import { Link } from "gatsby";
import { KeystoneImage } from "@/components/gatsby-image-keystone";
import { useLocation } from "@reach/router";
import { IoMdLink } from "react-icons/io";
import { CustomRenderer } from "@/utils/CustomRenderer";
import convertSlug from "@/utils/convertSlug";

const JobCart = (e: any, key: any) => {
  const path = useLocation().href + `/${e.id}` + `/${convertSlug(e.title)}`;

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleButtonClick = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };

  return (
    <div key={key} className="flex flex-col gap-[1rem]">
      {/* Image */}
      <div className="w-full max-h-[120px] overflow-hidden items-center align-middle justify-center">
        {e.featureImage && (
          <KeystoneImage
            baseUrl="steppelink"
            image={{
              alt: e.featureImage.id,
              width: 400,
              height: e.featureImage.height * (400 / e.featureImage.width),
              key: `${e.featureImage.id}.${e.featureImage.extension}`,
            }}
            layout="fullWidth"
            alt={e.featureImage.id}
          />
        )}
      </div>

      {/* Info */}
      <div>
        <p>{e.title}</p>
        {/* <p>1,200,000 MNT</p> */}
      </div>

      {/* Line */}
      <div className="w-full h-[1px] bg-gray dark:bg-white"></div>

      {/* Learn more */}
      {/* <div className="flex justify-between">
                <button className="border-[2px] z-10 border-gray dark:border-white px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
                  Learn more
                </button>

                <div className={`my-auto`}>
                  <BsFillTriangleFill size={15} />
                </div>
              </div> */}

      {/* Short description */}
      <div>
        <div>
          <CustomRenderer document={e.content.document} />
        </div>

        {/* Apply */}
        <div className="flex justify-end mt-[1rem]">
          <div className="relative" onClick={handleButtonClick}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(path);
              }}
              className="z-10 flex gap-[10px] px-[23px] rounded-tl-lg items-center rounded-br-lg hover:underline"
            >
              <IoMdLink className="my-auto" />
              <p>Share</p>
            </button>
            {isAlertVisible && (
              <div className="absolute top-[100%] rounded-lg right-[50%] translate-x-[50%] border-[1px] dark:bg-white px-[10px]">
                <p className="dark:text-black">Copied!</p>
              </div>
            )}
          </div>
          <Link to={`/join-us/${e.id}/${convertSlug(e.title)}`}>
            <button className="border-[2px] z-10 text-white bg-orange border-gray dark:border-white px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orangeDark">
              Apply
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JobCart;
