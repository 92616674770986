import React from "react";

import Layout from "@/components/Layout";
import Abilities from "@/components/AboutUs/Abilities";
import { CgSmileSad } from "react-icons/cg";
import Questions from "@/components/JoinUs/Questions";
import { graphql } from "gatsby";
import JobCart from "@/components/JoinUs/jobCart";

export default function JoinUs({ data }: any) {
  const jobs = data.cms.jobVacancies;

  return (
    <Layout title="Home">
      <div className="lg:container px-[20px] lg:px-0">
        <div className="">
          <div className="mb-[4rem] md:w-[70%]">
            <h1 data-aos="fade-up" className="mb-[1rem] sm:mb-[2rem]">
              Behind every great project is a
              <span className="text-orange"> great team</span>
            </h1>
            <p data-aos="fade-up">
              Good team work is a group of people who can communicate
              effectively and constructively to solve problems. It can
              strengthen and unify the company, project or department.
            </p>
          </div>
          {/* Abilities */}
          <div>
            <h1 data-aos="fade-right">
              What makes us
              <span className="text-orange"> good</span>
            </h1>
            <Abilities />
          </div>
        </div>

        {/* Application */}
        <div className="my-[4rem] space-y-6">
          <h1>Open Vacancies</h1>
          {jobs.length == 0 ? (
            <div
              data-aos="fade-up"
              className="flex my-[4rem] md:my-[6rem] gap-[2rem] text-black bg-white border-[2px] rounded-xl border-orange py-[10px] w-full lg:container justify-center align-middle px-[10px] items-center relative"
            >
              <div className="bg-orange text-white rounded-xl px-[10px] py-[5px]">
                <CgSmileSad size={30} />
              </div>
              <p className="font-[400]">
                Sorry, we do not currently have any job vacancies.
              </p>
            </div>
          ) : (
            <>
              <div className="relative bg-white dark:bg-gray py-[1rem] px-[1rem] md:py-[2rem] md:px-[4rem] lg:container grid gap-[4rem] grid-cols-1 md:grid-cols-2">
                {jobs.map((e: any) => JobCart(e, e.id))}
              </div>
            </>
          )}
        </div>

        {/* Questions */}
        <Questions />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query JoinPageQuery {
    cms {
      jobVacancies(where: { status: { equals: "published" } }) {
        contentFull {
          document(hydrateRelationships: false)
        }
        content {
          document(hydrateRelationships: false)
        }
        id
        title
        salary
        publishDate
      }
    }
  }
`;
