import React, { useState } from "react";

const Questions = () => {
  const [status, setStatus] = useState(false);
  const [status2, setStatus2] = useState(false);

  return (
    <div
      data-aos="fade-up"
      className="xl:container xl:px-0 px-[20px] pb-[3rem] flex flex-col gap-[3rem]"
    >
      <div
        className={`grid grid-cols-[max-content_80%_max-content] overflow-hidden justify-between h-[5rem] transition-all duration-300 ease-in-out border-b-[1px] pb-[2rem] cursor-pointer select-none ${
          status == true ? "h-[8rem]" : ""
        }`}
        onClick={() => {
          setStatus(!status);
        }}
      >
        <p className="text-[24px] p-[10px] text-white bg-orange font-[700] my-auto">
          01
        </p>

        <p className="sm:text-[22px] ml-[1rem] font-[700] my-auto">
          How many projects a year do you develop and maintain?
        </p>

        <div className="my-auto">
          {status == true ? (
            <button
              onClick={() => {
                setStatus(!status);
              }}
              className="text-orange w-[1rem] h-max font-[300] text-[30px]"
            >
              -
            </button>
          ) : (
            <button
              onClick={() => {
                setStatus(!status);
              }}
              className="text-orange w-[1rem] h-max font-[300] text-[30px]"
            >
              +
            </button>
          )}
        </div>
        <div></div>

        <p
          className={`mt-[1rem] ease-in-out duration-300 ${
            status == false ? "translate-y-[100%]" : ""
          }`}
        >
          In a year, we start developing and maintain around 4-6 projects. The
          size of the project varies, but it can take 2 months for version 1 of
          an application or website to launch, with designing, developing,
          testing.
        </p>
      </div>

      <div
        onClick={() => {
          setStatus2(!status2);
        }}
        className={`grid grid-cols-[max-content_80%_max-content] overflow-hidden justify-between h-[5rem] transition-all duration-300 ease-in-out border-b-[1px] pb-[2rem] cursor-pointer select-none ${
          status2 == true ? "h-[8rem]" : ""
        }`}
      >
        <p className="text-[24px] p-[10px] text-white bg-orange font-[700] my-auto">
          02
        </p>

        <p className="sm:text-[22px] ml-[1rem] font-[700] my-auto">
          Do you take interns? And are they paid?
        </p>

        <div className="my-auto">
          {status2 == true ? (
            <button
              onClick={() => {
                setStatus2(!status2);
              }}
              className="text-orange w-[1rem] h-max font-[300] text-[30px]"
            >
              -
            </button>
          ) : (
            <button
              onClick={() => {
                setStatus2(!status2);
              }}
              className="text-orange w-[1rem] h-max font-[300] text-[30px]"
            >
              +
            </button>
          )}
        </div>
        <div></div>

        <p
          className={`mt-[1rem] ease-in-out duration-300 ${
            status2 == false ? "translate-y-[100%]" : ""
          }`}
        >
          Yes! We interview every intern who want to do their internship at
          Steppe Link LLC and take those who are up for a challenge.
        </p>
      </div>
    </div>
  );
};

export default Questions;
